@use '~/scss/main.scss';

@mixin panel {
  padding-top: main.space(4);
  padding-bottom: main.space(2);

  @media (min-width: main.$tablet) {
    padding-top: main.space(3);
    padding-bottom: main.space(3);
  }
}

.whitePanel {
  @include panel;
}

.colorPanel {
  @include panel;

  background-color: main.$black;
  a {
    color: main.$white;
  }
}

.container {
  @include main.full-width;

  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}

.brand {
  a {
    @include main.brand;

    font-size: 16px;
  }

  @media (min-width: main.$tablet) {
    a {
      font-size: 18px;
    }
  }
}

/*
.color {
    color: hsl(240,100,70);
}
*/

.nav {
  display: flex;
  gap: main.space(2);

  a {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: main.$condensed;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  @media (min-width: main.$tablet) {
    gap: main.space(4);
    a {
      font-size: 16px;
    }
  }
}
