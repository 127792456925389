@use '~/scss/main.scss';

.panel {
  padding-top: main.space(9);
  padding-bottom: main.space(9);

  @media (min-width: main.$tablet) {
    padding-top: main.space(12);
    padding-bottom: main.space(12);
  }

  @media (min-width: main.$desktop) {
    padding-top: main.space(16);
    padding-bottom: main.space(16);
  }
}

.light {
  background-color: main.$white;
  color: main.$black;
}

.medium {
  background-color: main.$lily;
  color: main.$black;
}

//background-color: hsl(240, 40, 12);
.dark {
  background-color: main.$black;
  color: main.$white;

  ::selection {
    background-color: hsl(0, 0, 100);
    color: hsl(0, 0, 0);
  }
}

.half {
  background-image: linear-gradient(
    180deg,
    hsl(240, 50, 98) 1%,
    hsl(240, 50, 98) 61%,
    hsl(0, 0, 100) 61%,
    hsl(0, 0, 100) 99%
  );
}
