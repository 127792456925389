@use '~/scss/main.scss';

.box {
  @include main.full-width;

  display: grid;
  margin-top: main.space(7);
  grid-template-areas:
    'date'
    'title'
    'tags'
    'description'
    'read';
  grid-template-columns: 1fr;

  @media (min-width: main.$tablet) {
    margin-top: main.space(8);
    grid-template-areas:
      'date tags'
      '.    title'
      '.    description'
      '.    read';
    grid-template-columns: 1fr 3fr;
  }

  @media (min-width: main.$desktop) {
    margin-top: main.space(12);
  }
}

.date {
  margin-bottom: main.space(1);
  font-family: main.$mono;
  font-size: 12px;
  font-weight: 400;
  grid-area: date;
}

/*
.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: main.space(2);

  height: min-content;
}
*/

.block {
  height: 6px;
  aspect-ratio: 1/1;
  background-color: main.$ultra;
}

.info {
  display: flex;
  flex-direction: column;
  gap: main.space(1);
}

#title {
  margin-bottom: main.space(1);
  font-family: main.$condensed;
  font-size: 24px;
  font-weight: 500;
  grid-area: title;

  a {
    text-decoration: none;
  }

  @media (min-width: main.$tablet) {
    margin-bottom: main.space(2);
    font-size: 28px;
  }
}

.description {
  color: main.$dark-gray;
  font-family: main.$sans;
  font-size: 16px;
  font-weight: 400;
  grid-area: description;
  line-height: 1.4;

  @media (min-width: main.$tablet) {
    font-size: 17px;
  }

  @media (min-width: main.$desktop) {
    width: 80%;
    font-size: 18px;
  }
}

.tags {
  display: flex;
  margin-bottom: main.space(1);
  font-family: main.$mono;
  font-size: 12px;
  gap: main.space(1);
  grid-area: tags;
}

.read {
  display: flex;
  align-items: center;
  margin-top: main.space(1);
  color: main.$ultra;
  font-family: main.$mono;
  font-size: 14px;
  font-weight: 500;
  gap: main.space(1);
  grid-area: read;
}
