@use '~/scss/main.scss';

.content {
  @include main.full-width;

  display: grid;
  grid-template-areas:
    'brand brand'
    'nav   links'
    'copy  copy ';
  grid-template-columns: 1fr 1fr;

  @media (min-width: main.$tablet) {
    grid-template-areas:
      'brand nav  links'
      'copy  copy copy';
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  margin-top: main.space(4);
  font-family: main.$sans;
  font-size: 14px;
  gap: main.space(1);
  grid-area: nav;

  a {
    padding-top: 4px;
    padding-bottom: 4px;
    color: main.$gray-light;
    text-decoration: none;
  }

  @media (min-width: main.$tablet) {
    margin-top: 0;
    gap: main.space(2);
  }
}

#links {
  display: flex;
  flex-direction: column;
  margin-top: main.space(4);
  font-family: main.$sans;
  font-size: 14px;
  grid-area: links;
  row-gap: main.space(1);

  a {
    color: main.$gray-light;
    text-decoration: none;
  }

  @media (min-width: main.$tablet) {
    margin-top: 0;
    gap: main.space(2);
  }
}

#linkItem {
  padding-top: 4px;
  padding-bottom: 4px;
}

.copyright {
  margin-top: main.space(4);
  font-family: main.$sans;
  font-size: 14px;
  grid-area: copy;

  @media (min-width: main.$tablet) {
    margin-top: main.space(6);
  }
}

.brand {
  grid-area: brand;

  @include main.brand;
}

.heading {
  margin-bottom: main.space(1);
  color: main.$white;
  font-family: main.$sans;
  font-weight: 500;
}
