@use '~/scss/main.scss';

.container {
  @include main.full-width;

  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: main.$tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.info {
  display: flex;
  flex-direction: column;

  padding: main.space(2);
  padding-top: main.space(4);
  padding-bottom: main.space(4);
  margin-top: main.space(6);
  background-color: main.$cosmos;
  box-shadow: main.$hover-shadow;
  color: main.$white;
  font-family: main.$mono;
  font-size: 14px;
  font-weight: 400;
  gap: main.space(1);

  @media (min-width: main.$tablet) {
    padding: main.space(4);
    margin-top: 0;
    aspect-ratio: 2/1;
  }
}

.name {
  font-weight: 400;
}

.title {
  color: main.$cosmos;
  font-family: main.$sans;
  font-size: 20px;
  font-weight: 500;

  @media (min-width: main.$tablet) {
    font-size: 24px;
  }

  @media (min-width: main.$desktop) {
    font-size: 28px;
  }
}

.sub {
  color: main.$dark-gray;
  font-family: main.$sans;
  font-size: 20px;
  font-weight: 400;

  @media (min-width: main.$tablet) {
    font-size: 20px;
  }

  @media (min-width: main.$desktop) {
    font-size: 24px;
  }
}
