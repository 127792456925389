@use '~/scss/main.scss';

.all {
  display: flex;
  flex-direction: column;
}

.headingBox {
  @include main.full-width;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: main.space(10);
}

.heading {
  font-family: main.$sans;
  font-size: 24px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: main.$ultra;
  text-decoration-thickness: 3px;

  @media (min-width: main.$tablet) {
    font-size: 24px;
  }
}

/*
.block {
    height: 8px;
    aspect-ratio: 1/1;
    background-color: main.$ultra;
}
*/
