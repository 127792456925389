/*************************************************
 * Utilities
 ************************************************/
// @function pow($base, $exponents)
// Helper: Raise a number the power of another number
// Adapted from: https://gist.github.com/hail2u/1964056
//
// @param {number} $base — The base number
// @param {number} $exponents — The exponent to which to raise $base
@function pow($base, $exponents) {
  @if $exponents == 0 {
    @return 1;
  }

  $raised: 1;
  @for $i from 1 through $exponents {
    $raised: $raised * $base;
  }
  @return $raised;
}

/*************************************************
 * Color
 ************************************************/
$transparent: hsla(0, 0%, 0%, 0);

// grayscale

@function gray($step) {
  @return hsla(0, 0%, 100 - 10 * $step);
}

// colors
$cultured: hsla(40, 30%, 98%, 1);
$cultured-20: hsla(40, 30%, 95%, 1);
$blue: 221;
$green: 140;

@function color($base-color, $step) {
  @return hsla($base-color, 70%, calc(100 - $step * 10), 1);
}

/*************************************************
 * Typography
 ************************************************/

// families
$sans: var(--font-sans), sans-serif;
$condensed: var(--font-condensed), sans-serif;
$mono: var(--font-mono), monospace;

// scales
$base-font-size: 1rem;
$font-ratio: 1.25;
$font-smaller-ratio: 0.8;

@function font-size($step) {
  @return $base-font-size * pow($font-ratio, $step - 1);
}

@function font-size-smaller($step) {
  @return $base-font-size * pow($font-smaller-ratio, $step);
}

// weight
$font-weight-200: 200;
$font-weight-400: 400;
$font-weight-600: 600;

/*************************************************
 * Spacing
 ************************************************/

// 8px space scale
$base-space-unit: 8px;
@function space($step) {
  @return $base-space-unit * $step;
}

@function space-smaller($step) {
  @return $base-space-unit - 2 * $step;
}

/*************************************************
 * Tokens
 ************************************************/
$slim-shadow: rgba(0, 0, 0, 0.1) 0 2px 5px -1px,
  rgba(0, 0, 0, 0.1) 0 1px 3px -1px;
$hover-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
  rgba(0, 0, 0, 0.3) 0 8px 16px -8px;

$line-height: 1.4211;

@mixin breakout-container {
  @include container;

  width: 100%;
  max-width: 450px;

  @media (min-width: 734px) {
    width: 692px;
    max-width: none;
  }

  @media (min-width: 1024px) {
    width: 690px;
  }
}

@mixin code {
  // do not bleed white highlight into background white
  //border: solid 1px colors.$cool-gray-20;
}

/*************************************************
 * Other
 ************************************************/

@mixin svg-hover-color($color) {
  &:hover {
    svg {
      color: $color;
    }
  }
}

@mixin scrollbar(
  $size: 4px,
  $foreground-color: hsl(0, 0, 30),
  $background-color: rgba(0, 0, 0, 0)
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: space(1);
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: space(1);
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}

/******************************************************************************
 * FINAL
 ******************************************************************************/

// control width of any element to prevent long lines
@mixin container {
  width: 87.5%;
  max-width: 414px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 734px) {
    width: 576px;
    max-width: none;
  }

  @media (min-width: 1024px) {
    width: 653px;
  }
}

$tablet: 768px;
$desktop: 1024px;

@mixin full-width {
  width: 87.5vw;
  max-width: 414px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $tablet) {
    width: 730px;
    max-width: none;
  }

  @media (min-width: $desktop) {
    width: 980px;
  }
}

// ui text remains constant at 14px
@mixin ui {
  font-size: 14px;
}

@mixin tag {
  @include ui;

  background-color: hsl(240, 28, 95);
  color: hsl(240, 100, 30);

  font-family: $condensed;
  font-weight: 400;
  text-transform: lowercase;
}

@mixin content {
  font-family: $sans;
  font-size: 16px;

  @media (min-width: 734px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 19px;
  }
}

@mixin heading-1 {
  font-size: 32px;
  font-weight: 300;

  @media (min-width: 734px) {
    font-size: 40px;
  }

  @media (min-width: 1440px) {
    font-size: 48px;
  }
}

@mixin heading-2 {
  font-family: $sans;
  font-size: 21px;
  font-weight: 400;

  @media (min-width: 1440px) {
    font-size: 24px;
  }
}

@mixin brand {
  font-family: $mono;
  font-weight: 600;
  letter-spacing: -0.06em;
}

@mixin bold {
  font-family: $sans;
  font-weight: 600;
  letter-spacing: -0.03em;
}

$color-fade: hsl(0, 0, 70);
$color-secondary: hsl(0, 0, 80);
$color-primary: hsl(0, 0, 0);

$black: hsl(0, 0, 0);
$white: hsl(0, 0, 100);
$dark-gray: hsl(0, 0, 25);
$light-gray: hsl(0, 0, 75);
$gray-dark: hsl(0, 0, 25);
$gray-light: hsl(0, 0, 75);
$lily: hsl(240, 50, 98);
$azure: hsl(215, 80, 55);
$azure-light: hsl(215, 80, 85);
$ultra: hsl(240, 100, 65);
$ultra-light: hsl(240, 100, 80);
$indigo: hsl(265, 100, 50);
$indigo-light: hsl(265, 100, 85);
$folly: hsl(350, 100, 65);
$folly-light: hsl(350, 100, 80);
$cosmos: hsl(240, 50, 15);

$content-bound: 65%;
